/* botStyles.css */
.botstyles pre {
  background-color: #eee;
  border: 1px solid #dfdfdf;
  padding: 5px 10px;
  border-radius: 4px;
  font-family: system-ui;
}

.botstyles code {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.botstyles .md:not(.use-csslab) pre code {
  white-space: pre-wrap;
}

.botstyles ul {
  padding-left: 20px;
  margin: 0;
}

.botstyles li {
  padding-left: 10px;
}

.botstyles p {
  margin: 0;
}

.botstyles a {
  text-decoration: underline;
  color: #0073c8;
}

.tuicon:hover svg,
.tdicon:hover svg {
  width: 20px;
  height: 20px;
}

.tuicon.thumbsup path, .tdicon.thumbsdown path{
  stroke-dasharray: 50;
  stroke-dashoffset: 0;
}
.thumbsup:hover, .thumbsdown:hover{
  transform: scale(1.2);
}
.thumbsup:hover svg path{
  fill: #228c22;
}
.thumbsdown:hover svg path{
  fill: #cc0000;
}
.tuicon.thumbsup.active svg, .tdicon.thumbsdown.active svg{
  transform: scale(1.2);
}
.tuicon.thumbsup.active path{
  animation: animate-heart-success 1.2s linear forwards;
}
.tdicon.thumbsdown.active path{
  animation: animate-heart-failure 1.2s linear forwards;
}
@keyframes animate-heart-success {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 50;
  }
  80% {
    stroke-dashoffset: 100;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 100;
    fill: #228c22;
  }
}
@keyframes animate-heart-failure {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 50;
  }
  80% {
    stroke-dashoffset: 100;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 100;
    fill: #cc0000;
  }
}
