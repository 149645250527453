.circle {
    position: absolute;
    z-index: -1;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 1px;
    background-clip: content-box;
    animation: spin 10s linear infinite;
  }

.success {
    background-color: rgb(0, 169, 165, 0.1);
    border: 2px dashed #00A9A5;
}

@keyframes spin {
    100% {
      transform: rotateZ(360deg);
    }
}
.toolkit ul a {
    width: 80px;
}
.toolkit ul a div {
    width: 80px;
    height: 80px;
    font-size: 14px;
}

.toolkit ul a div p  {
    width: 80px;
    font-weight: 500;
}